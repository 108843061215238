* {
  font-family: "Lato", sans-serif;
}

.banner {
  background-image: url("/src/background.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 35rem;
  width: 100vw;
}

.banner-inner {
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
}

.banner-inner h2 {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  margin: 0rem;
}

.banner-inner p {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
}

@media screen and (max-width: 880px) {
  .banner {
	  background-attachment: scroll;
	  background-size: cover;
	  height: 22.5rem;
	  width: 100%;
  }

  .banner-inner {
	  padding: 1rem;
  }
  .banner-inner h2 {
    font-size: 2.4rem;
  }
}
