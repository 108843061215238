* {
  font-family: "Lato", sans-serif;
}

.about {
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 35rem;
}

.about-content {
  width: 80%;
  align-items: center;
  justify-content: space-around;
}


.about-content-img {
  width: 80%;
  &.cofi {
    width: 30%;
  }
}

.img-link {
  @media screen and (max-width: 767px) {
    width: 100%;
  };
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-content-text-links {
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}




.about-content-line {
  height: 10rem;
  border-right: 1px solid #e8e8e8;
  margin: 0 2rem;
}

.about-content-text {
  width: 50%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}

.about-content-text-links {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
}

.about-content-text h4 {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.2rem;
  margin: 0;
}

.about-content-text h3 {
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 0.1rem;
  margin: 1rem;
}

.about-content-text p {
  margin: 1.25rem 0rem 2rem 0rem;
  letter-spacing: 0.025rem;
  font-size: 1rem;
  color: #777777;
  font-weight: 300;
}

.openings {
  display: flex;
  width: 10rem;
  text-decoration: none;
  border: 2px solid #3673cf;
  height: 3.125rem;
  padding: 0 1.5625rem;
  font-size: 1.2rem;
  font-weight: 400;
  border-radius: 2rem;
}

.openings svg {
  fill: #3673cf;
  width: 10%;
  height: 100%;
  margin: 0 0.2rem;
}

.openings span {
  color: #3673cf;
  margin: 0 0.2rem;
}

@media screen and (max-width: 880px) {
  .about {
    /* height: 100vh; */
    align-items: center;
  }

  .about-content {
    flex-direction: column;
    width: 100%;
  }

  .about-content-text {
    width: 80%;
  }

  .about-content-text h3 {
    font-size: 1.5rem;
  }
  .about-content-img {
    max-width: 60%;
  }

  .about-content-line {
    height: 0rem;
    border-right: 0;
    margin: 2rem 0;
    width: 80%;
    border-bottom: 1px solid #e8e8e8;
  }
}
