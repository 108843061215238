.footer {
  width: 100%;
  height: 20rem;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.footer-content {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-logo {
    cursor: pointer;
}

.footer-text {
    padding: 0.5rem;
}

.footer-text p {
  text-align: center;
  font-weight: 300;
}

.copyright a {
    text-decoration: none;
    color: inherit;
    transition: 0.2s ease-in-out
}

.copyright a:hover {
    color: #919191
}

.address {
    font-size: 0.8rem;
}

.icons {
  display: flex;
  justify-content: space-evenly;
}

.icons svg {
  fill: #787878;
  margin: 1rem;
  padding: 0.6rem;
  border-radius: 100%;
  border: 1px solid #787878;
  height: 1.4rem;
  width: 1.4rem;
  transition: fill 0.25s ease;
}

.instagram svg:hover {
  fill: #e1306c;
  border-color: #e1306c;
}

.linkedin svg:hover {
  fill: #0072b1;
  border-color: #0072b1;
}

.twitter svg:hover {
  fill: #1da1f2;
  border-color: #1da1f2;
}

.medium svg:hover {
  fill: #000000;
  border-color: #000000;
}

@media screen and (max-width: 880px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-logo {
    max-width: 70%;
  }
}
