* {
    font-family: 'Lato', sans-serif;
}

.main {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.main-content {
    width: 80%;
    align-items: center;
    justify-content: space-around;
}

.main-content img {
    width: 40%;
}

.main-content-line {
    height: 10rem;
    border-right: 1px solid #E8E8E8;
    margin: 0 2rem;
}

.main-content-text {
    flex-direction: column;
    text-align: center;
}

.main-content-text h3 {
    margin: 0rem;
    font-size: 2.5rem;
    font-weight: 700;
}

.main-content-text p {
    margin: 1.25rem 0rem 2rem 0rem;
    letter-spacing: 0.025rem;
    font-size: 1rem;
    font-weight: 300;
}

.icons {
    display: flex;
    justify-content: space-evenly;
}

.icons svg {
    fill: #787878;;
    padding: 0.6rem;
    border-radius: 100%;
    border: 1px solid #787878;
    height: 1.4rem;
    width: 1.4rem;
    transition: fill 0.25s ease;
}

.instagram svg:hover {
    fill: #E1306C;
    border-color: #E1306C;
}

.linkedin svg:hover {
    fill: #0072B1;
    border-color: #0072B1;
}

.twitter svg:hover {
    fill: #1DA1F2;
    border-color: #1DA1F2;
}

.medium svg:hover {
    fill: #000000;
    border-color: #000000;
}

@media screen and (max-width: 880px) {
    .main-content {
        flex-direction: column;
        /* justify-content: center; */
    }

    .main-content-text h3 {
        font-size: 2rem;
    }
    .main-content img {
        width: 80%;
    }

    .main-content-line {
        height: 0rem;
        border-right: 0;
        margin: 4rem 0;
        width: 80%;
        border-bottom: 1px solid #E8E8E8
    }
}