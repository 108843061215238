.team {
  align-items: center;
  flex-direction: column;
  margin-bottom: 10rem;
}

.team h2 {
  font-size: 3rem;
}

.members {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 80%;
}

.member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  margin: 1rem;
  gap: 10px;
}

.member-profile-picture {
  width: 300px;
  height: 300px;
}

.member-profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.member-name {
  font-size: 2rem;
  margin: 0;
}

.member-position {
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.1rem;
  text-align: center;
  margin: 0;
}

.linkedin svg {
  fill: #787878;
  padding: 0.6rem;
  border-radius: 100%;
  border: 1px solid #787878;
  height: 1.4rem;
  width: 1.4rem;
  transition: fill 0.25s ease;
}

.linkedin svg:hover {
  fill: #0072b1;
  border-color: #0072b1;
}

@media screen and (max-width: 880px) {
  .team {
    width: 100%;
  }

  .team h2 {
    font-size: 3rem;
  }

  .members {
    max-width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;

    justify-content: flex-start;
  }

  .member-profile-picture {
    width: 200px;
    height: 200px;
  }

  .member-name {
    font-size: 2rem;
  }

  .member-position {
    font-size: 1rem;
  }
  
}
